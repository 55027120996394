export function getStatusText(value) {
  switch (value) {
    case "U":
      return "Chưa thi";
    case "P":
      return "Vào phòng thi"
    case "S":
      return "Đang thi";
    case "F":
      return "Kết thúc thi";
    default:
      return "Không tìm thấy Trạng thái"
  }
}

export function getExaminationStatus(examination, now) {
  if (
    !examination.startTime ||
    now < new Date(examination.startTime)
  ) return { value: "U", text: getStatusText("U") };

  if (
    !examination.startExamTime ||
    now < new Date(examination.startExamTime)
  ) return { value: "P", text: getStatusText("P") };

  if (
    !examination.endTime ||
    now < new Date(examination.endTime)
  ) return { value: "S", text: getStatusText("S") };

  return { value: "F", text: getStatusText("F") };
}
