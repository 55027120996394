import request from "@/utils/request";

export default {
  data() {
    return {
      serverTime: new Date()
    };
  },

  created() {
    this.startFetchTimeInterval();
  },

  beforeDestroy() {
    this.clearFetchTimeInterval();
  },

  methods: {
    async fetchServerTime() {
      const res = await request({
        url: "/time"
      });

      this.serverTime = new Date(res.data);
    },

    startFetchTimeInterval() {
      this.fetchServerTime();

      this.intervalId = setInterval(() => {
        this.fetchServerTime();
      }, 10 * 1000);
    },

    clearFetchTimeInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = undefined;
      }
    },
  }
}